import React, { Fragment, useEffect, useState } from "react";
import { ArrowSmDownIcon, XIcon } from "@heroicons/react/solid";
import accounting from "accounting";

import { List } from "../../style/List";
import { ListItemHp } from "../../style/ListItemHp";
import { Text } from "../../style/Text";
import { Button } from "../../style/Button";
import config from "../../configs";
import { OF_FIRST_STEP_SLUG } from "../../constants";
import { Box } from "../../style/Grid";
import { Modal } from "../Modal";
import { SwitchToggle } from "../../style/SwitchToggle";
import { MarkupToHtml } from "../../helpers/parse";
import PdfViewer from "../PdfViewer/PdfViewer";
import { getArrangeOffersAdditionalParams } from "../SavingsCalculation/utils/commodity-additional-params";

const Hp = ({ commodity, offers, bottomLinkList, additionalParams = undefined }) => {
  const [selected, setSelected] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const showSwitchToggle = offers.length > 1;
  const selectedOffer = offers[selected];
  const { subtitle, offerCode } = selectedOffer;
  const showSubtitle = !!subtitle;
  const isElectricity = commodity === "electricity";


  const pickedAdditionalParams = getArrangeOffersAdditionalParams({
    offerAdditionalParams: additionalParams,
    commodity,
  });

  const offerLinkCommodity = isElectricity ? "elektrina" : "plyn";


  const productParam = (offerCode && "product=" + offerCode) || "";

  const hasParams = Boolean(productParam || pickedAdditionalParams);

  const paramsPrefix = hasParams ? "?" : "";

  const productParamSplit = (productParam && pickedAdditionalParams) ? "&" : "";

  const completeParams = `${paramsPrefix}${productParam}${productParamSplit}${pickedAdditionalParams}`;

  const offerLink = `${config.gofu}${offerLinkCommodity}/${OF_FIRST_STEP_SLUG}${completeParams}`;

  const changeProduct = () =>
  {
    // switch nad selected (0 -> 1 / 1 -> 0) a cast zpět na number
    const newSelected = Number(!selected);

    setSelected(newSelected);

    const newOffer = offers[newSelected];
    sendEvent(newOffer);
  }

  const sendEvent = (eventOffer) =>
  {
    const event = new CustomEvent("activeOfferCode", { detail: { offerCode: eventOffer.offerCode, commodity } });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    window.addEventListener("activeOfferProduct", event => {
      if (event.detail.commodity === commodity) {
        const offerIndex = offers.findIndex(offer => offer.offerCode === event.detail.offerCode);

        if (offerIndex !== -1) {
          setSelected(offerIndex);
        }
      }
    });
  }, []);

  const onClick = (e) =>
  {
    const isCalcClick = (e.target.getAttribute('href') || '').includes('#kalkulacka');
    const isHref = e.target.tagName === 'A';

    if (isHref && isCalcClick)
    {
      sendEvent(selectedOffer);
    }
  }

  return (
    <Box className={"w-full flex"} py={20} px={20} pb={30} textAlign={"center"} backgroundColor={"white.100"} flexDirection="column" onClick={onClick}>
      <Text mb={14} fontSize={36} lineHeight={1.4} fontWeight={700} color={"green.dark"}>
        <img src={require(commodity === "gas" ? "../../images/HP_dark.gif" : "../../images/HP_el_dark.gif").default} alt="" className={"mb-0"} />
        <span>&nbsp;{commodity === "gas" ? "Plyn" : "Elektřina"}</span>
      </Text>

      {(showSubtitle && (
        <div className="mb-2 flex flex-col justify-between">
          <Text color={"exploratoryGreen"} fontWeight={600} minHeight={["auto"]} fontSize={"16px"} pb={"10px !important"}>
            <MarkupToHtml children={subtitle} />
          </Text>
        </div>
      )) ||
        null}

      {(showSwitchToggle && (
        <div className="mb-5">
          <SwitchToggle offersLength={offers.length} selected={selected} onClick={offers.length > 1 ? changeProduct : undefined}>
            <div>
              {offers.map((offer, index) => (
                <span key={`offer-option-${index}`} className={selected === index ? "active" : ""}>
                  {offer.switchText}
                </span>
              ))}
            </div>
          </SwitchToggle>
        </div>
      )) ||
        null}

      <div className="mb-5 flex flex-col justify-between">
        <Text lineHeight={1.3} color={"green.dark"} fontSize={"18px"} fontWeight={700}>
          {offers[selected].description ? (
            <>
              {offers[selected].description}
              <br />
            </>
          ) : (
            ""
          )}
          {accounting.formatMoney(offers[selected].price, "", 0, " ", ",")} Kč/MWh vč. DPH
          <>
            <br />
            <Text as={"span"} fontWeight={400} fontSize={15}>
              {offers[selected].priceBottomDescription || " "}
            </Text>
          </>
        </Text>

        {offers[selected].specialOfferTitle || offers[selected].specialOfferDescription ? (
          <div>
            {offers[selected].specialOfferTitle ? (
              <Text
                lineHeight={1.3}
                color={"resourceGreenLight"}
                fontSize={"18px"}
                mt={2}
                fontWeight={700}
                dangerouslySetInnerHTML={{ __html: offers[selected].specialOfferTitle }}
              />
            ) : null}

            {offers[selected].specialOfferDescription ? (
              <Text
                lineHeight={1.3}
                color={"resourceGreenLight"}
                fontSize={"14px"}
                fontWeight={400}
                dangerouslySetInnerHTML={{ __html: offers[selected].specialOfferDescription }}
              />
            ) : null}
          </div>
        ) : null}
      </div>

      <div className={"flex justify-center mb-4 flex-1 items-start"}>
        {(selectedOffer.textList.length && (
          <List className={"flex flex-col"} m={"0 auto"}>
            {selectedOffer.textList.map(({ text }, index) => (
              <Text
                key={`offer-text-${index}`}
                color="expertiseGray"
                as={props => <ListItemHp {...props} type="small" textAlign={(selectedOffer.textList.length >= 2 && "left") || "center"} />}
                mb={"3px"}
              >
                <Text color={"exploratoryGreen"} fontWeight={400} lineHeight={"22px"} textAlign="left">
                  <MarkupToHtml children={text} />
                </Text>
              </Text>
            ))}

            <PdfViewer
              linkText={offers[selected].name}
              srcURL={offers[selected].pricelist}
              commodity={commodity}
              offerCode={offerCode}
              useImage
              linkAS={props => <ListItemHp {...props} type="small" textAlign={"left"} />}
            />
          </List>
        )) ||
          null}
      </div>

      <div className={"flex justify-center mb-5 items-end"}>
        <Button as={"a"} minWidth={"210px"} variant={"primary"} href={offerLink}>
          Objednat online
        </Button>
      </div>

      <div className={"flex gap-2 justify-center mt-2 mb-2 items-center"}>
        {bottomLinkList?.map(({ content, icon }, index) => {
          const isIconCalculator = icon === "calculator";
          const isIconPhone = icon === "phone";

          return (
            <Fragment key={`offer-text-${index}`}>
              {index !== 0 && (
                <Text
                  display={"inline-flex"}
                  alignitems={"center"}
                  color={"expertiseGray"}
                  opacity={0.2}
                  mr={3}
                  ml={3}
                  fontSize="20px"
                  lineHeight={"22px"}
                  mb={"5px"}
                >
                  |
                </Text>
              )}
              <Text
                className={"newHP gap-2 text-center text-small underline hover:no-underline"}
                display={"inline-flex"}
                alignitems={"center"}
                color={"green.dark"}
                fontSize="14px"
                fontWeight={400}
              >
                {isIconCalculator && <img src={require("../../images/calc.png").default} width="20px" height="20px" alt="Kalkulačka" className="mb-0" />}
                {isIconPhone && <img src={require("../../images/phone.png").default} width="16px" height="16px" alt="Kontakt" className="mb-0" />}
                <MarkupToHtml>{content}</MarkupToHtml>
              </Text>
            </Fragment>
          );
        })}
      </div>

      {/*<Text fontSize={"14px"} lineHeight={1.45} color={"green.dark"} fontWeight={400}>*/}
      {/*  {offers[selected].compare.split("\n").map(line => (*/}
      {/*    <Fragment key={line}>*/}
      {/*      {line}*/}
      {/*      <br />*/}
      {/*    </Fragment>*/}
      {/*  ))}*/}
      {/*</Text>*/}
    </Box>
  );
};

export default Hp;
